import React from 'react';
import styled from 'styled-components';
import fontService, { FontWeight } from 'services/FontService';
import { TablesStatus } from 'constants/tables';
import { Button } from 'atoms';
import { StatusLabel } from 'molecules';

interface IProps {
  tableId?: string;
  status?: TablesStatus;
  isTableTaken: boolean;
  onOpenTableClick: () => void;
  onBackToChooseClick: () => void;
}

export const OpenTable = ({
  status,
  tableId,
  isTableTaken,
  onOpenTableClick,
  onBackToChooseClick,
}: IProps) => (
  <div>
    <Header>
      <Text>Dealer User Interface (v.1.3.1)</Text>
    </Header>
    <Container>
      <InfoWrapper>
        <StatusLabelWrapper>
          <StatusLabel status={status!} />
        </StatusLabelWrapper>

        <div>
          <TextTitle>TABLE ID</TextTitle>
          <TextId>{tableId}</TextId>
        </div>
      </InfoWrapper>

      <Separator />
      <ButtonContainer>
        {isTableTaken ? (
          <TextTaken>
            Table is active and unavailable.
            <br />
            Please choose another table
          </TextTaken>
        ) : (
          <OpenTableButton onClick={onOpenTableClick} type="button">
            {status === TablesStatus.Open ? 'reopen table' : 'open table'}
          </OpenTableButton>
        )}

        <BackToChooseButton onClick={onBackToChooseClick} type="button">
          CHOOSE ANOTHER TABLE
        </BackToChooseButton>
      </ButtonContainer>
    </Container>
  </div>
);

const Container = styled.div`
  width: 100%;
  max-width: 1152px;
  height: 592px;
  margin-top: 16px;
  margin: 16px auto 0;
  padding: 16px 24px;
  background: linear-gradient(180deg, rgba(52, 52, 52, 0) 0%, #343434 100%);
  border: 1px solid #bb9370;
  border-radius: 12px;
  z-index: 10;
`;
const Header = styled.div`
  width: 100%;
  height: 48px;
  background: #121011;
`;

const Text = styled.p`
  width: 100%;
  height: 100%;
  max-width: 1152px;
  ${fontService.text({ size: 10, weight: FontWeight.regular })};
  color: ${({ theme: { primaryTextColor } }) => primaryTextColor};
  margin: 0 auto;
  display: flex;
  align-items: center;
`;

const TextTitle = styled.p`
  ${fontService.text({ size: 8, weight: FontWeight.medium })};
  color: ${({ theme: { tertiaryTextColor } }) => tertiaryTextColor};
  text-transform: uppercase;
  margin-bottom: 4px;
`;

const TextId = styled.p`
  ${fontService.text({ size: 16, lineHeight: 112, weight: FontWeight.bold })};
  color: ${({ theme: { primaryTextColor } }) => primaryTextColor};
  text-transform: uppercase;
`;

const TextTaken = styled.p`
  ${fontService.text({ size: 24, lineHeight: 133, weight: FontWeight.bold })};
  color: ${({ theme: { primaryTextColor } }) => primaryTextColor};
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 32px;
`;

const Separator = styled.div`
  width: 100%;
  height: 1px;
  background: #bb9370;
`;

const CustomButton = styled(Button)`
  width: 290px;
`;

const OpenTableButton = styled(CustomButton)`
  text-transform: uppercase;
  margin-bottom: 16px;
  background: #3c8024;
  border: 1px solid rgba(67, 172, 29, 0.24);
  &:hover {
    background: #43ac1d;
    border: 1px solid rgba(67, 172, 29, 0.24);
    cursor: pointer;
  }
`;

const BackToChooseButton = styled(CustomButton)`
  text-transform: uppercase;
  margin-bottom: 16px;
  background: #434343;
  border: 1px solid rgba(67, 67, 67, 0.24);
  &:hover {
    background: #666666;
    border: 1px solid rgba(102, 102, 102, 0.24);
    cursor: pointer;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  height: 528px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const InfoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
`;

const StatusLabelWrapper = styled.div`
  margin-right: 24px;
`;
