import { put } from 'redux-saga/effects';
import * as Sentry from '@sentry/react';
import moment from 'moment';

import {
  bettingTimeStartedAction,
  changeGameStateAction,
  changeGameStatusAction,
  changeTimeLeftAction,
  setCardsStatusToStore,
  setIsBettingTime,
  setIsCancelRound,
  setIsScanTime,
  setIsStartRound,
} from 'data/reducers';
import { BettingTimeStartedResponse, Notifications, TableStates } from 'types';
import { gameStatus } from 'constants/common';

export function* bettingTimeStarted({
  type: notificationType,
  data: result,
}: BettingTimeStartedResponse) {
  try {
    if (notificationType === Notifications.BETTING_TIME_STARTED) {
      const { bettingTime, additional, burnCardRequired, shuffleRequired, finishBettingTime } =
        result;
      yield put(changeGameStatusAction({ status: gameStatus.BettingTime }));
      yield put(changeGameStateAction({ tableState: TableStates.BettingTimeStarted }));
      yield put(
        bettingTimeStartedAction({
          bettingTime,
          additional,
          burnCardRequired,
          shuffleRequired,
          finishBettingTime,
        }),
      );

      yield put(
        changeTimeLeftAction({
          bettingTimeLeft: undefined,
          // finishBettingTime,
          finishBettingTime: moment()
            .add(bettingTime, 'seconds')
            .utc()
            .format('YYYY-MM-DDTHH:mm:ss.SSS[Z]') as unknown as Date,
        }),
      );
      yield put(setIsStartRound(true));
      yield put(setIsBettingTime(true));
      yield put(setIsCancelRound(true));
      yield put(setIsScanTime(false));
      yield put(setCardsStatusToStore(null));
    }
  } catch (error) {
    Sentry.captureException({ error });
    console.log(error);
  }
}
